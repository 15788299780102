const RESOURCES = [
  { value: 'MyPhoenix home', url: 'https://my.phoenix.edu/home.html' },
  { value: 'Blackboard', url: 'https://vle.phoenix.edu/webapps/login/?action=login' },
  { value: 'Academic Plan', url: 'https://portal.phoenix.edu/te/AcademicPlan/index.html' },
  { value: 'Financial Plan', url: 'https://ecampus.phoenix.edu/financialplan/' },
  { value: 'Library', url: 'https://portal.phoenix.edu/library.html' },
  { value: 'Knowledgebase', url: 'http://studentkb.phoenix.edu/Search/GetSearch/12/187?searchFrom=Home&text=' },
  { value: 'University Contacts', url: 'https://my.phoenix.edu/university-contacts.html' },
];

export default RESOURCES;
