import ErrorContent from '@/components/organisms/error-content';
import Layout from '@/pages/_layout';

const title = 'Oh dear! We couldn\'t find the page you\'re looking for.';

function Page404() {
  return <ErrorContent title={title} error={404} showMoreResources />;
}

Page404.getLayout = function getLayout(page: JSX.Element) {
  return (
    <Layout title="404">{page}</Layout>
  );
};

export default Page404;
